import { BaseClass } from './base-class';
import { Component, afterNextRender } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'VTTS-FE';
  constructor(
    private router: Router,
    private baseClass: BaseClass
  ) {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((val: any) => {
      if (this.baseClass.checkPlatform()) {
        // document.addEventListener('DOMContentLoaded', (event) => {
        const elements1 = document.querySelectorAll('.animated');
        if (elements1?.length) {
          // console.log(elements1);
          elements1.forEach(el => {
            let tempNameAnimation = el?.getAttribute('animation');
            if (tempNameAnimation)
              el.classList.remove(tempNameAnimation)
          }
          );
        }
        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            let nameAnimation = entry.target.getAttribute('animation');

            if (nameAnimation)
              if (entry.isIntersecting) {
                entry.target.classList.add(nameAnimation, 'animated-fast');
              } else {
                // entry.target.classList.remove(nameAnimation);
              }
          });
        });

        const elements = document.querySelectorAll('.animated');
        elements.forEach(el => observer.observe(el));
        // });
      }
    });
  }
}
